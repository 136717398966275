<template>
  <div>
    <portal to="header">
      <terminal-title :text="$t('Receiving')" />
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema" />
    </portal>

    <div class="q-pa-md">
      <form-builder :schema="schema" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Acceptance',
  data () {
    return {
      types: [
        { id: 'products', name: this.$t('Products') },
        { id: 'items', name: this.$t('Items') },
        { id: 'palletized', name: this.$t('Palletized') },
        { id: 'batch', name: this.$t('Batch & EXP') }
      ],
      model: {
        type: 'products',
        extId: '',
        comment: '',
        extDate: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
        _embedded: {
          warehouse: null
        }
      }
    }
  },
  computed: {
    tilesSchema () {
      return [
        {
          id: 1,
          label: this.$t('Save'),
          icon: 'save',
          value: true,
          onChanged: () => {
            this.save()
          }
        }
      ]
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row full-width',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Ext ID'),
                value: this.model.extId,
                wrapperStyleClasses: 'col-12 col-md-3 q-pa-sm',
                onChange: value => {
                  this.model.extId = value
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                value: this.model.type,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                wrapperStyleClasses: 'col-12 col-md-3 q-pa-sm',
                options: this.types,
                onChange: (type) => {
                  this.model.type = type.id
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Warehouse'),
                value: this.model._embedded.warehouse,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-sm',
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.warehouse.getAll(query)
                },
                onChange: (warehouse) => {
                  this.model._embedded.warehouse = warehouse
                }
              },
              {
                type: 'date',
                label: this.$t('External date'),
                value: this.model.extDate,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-sm',
                onChange: extDate => {
                  this.model.extDate = extDate
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Comment'),
                value: this.model.comment,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-sm',
                onChange: comment => {
                  this.model.comment = comment
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    save () {
      const data = this.$utils.convertEmbedded(this.model)

      return this.$service.acceptance.save(data)
        .then(() => {
          this.handleBack()
        })
    },
    handleBack () {
      this.$router.push('/terminal/acceptances')
    }
  }
}
</script>
